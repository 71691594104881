import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import Footer from '../Components/Footer';
import env from '../utils/env';
import { useAuth } from "../Context/AuthContext";

const ClassList = () => {
  const [classes, setClasses] = useState([]);
  const { user } = useAuth();

  useEffect(() => {
    const fetchClasses = async () => {
        try {
          const response = await fetch(`${env.base_url}schools/${user.school.id}/classes`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          const { data } = await response.json();
          setClasses(data);

        } catch (error) {
          console.error("Erro ao buscar os dados das turmas:", error);
        }
    };

    fetchClasses();
  }, [user]);

  // Função para converter os dados em CSV
  const convertToCSV = (data) => {
    const header = ['ID', 'Nome da Turma', 'Descrição', 'Idade', 'Mensalidade'];
    const rows = data.map((classe) => [
      classe.id,
      classe.name,
      classe.description,
      `${classe.min_age}-${classe.max_age} anos`,
      `${classe.amount} MZN`
    ]);

    return [header, ...rows].map(e => e.join(",")).join("\n");
  };

  // Função para baixar o arquivo CSV
  const downloadCSV = () => {
    const csv = convertToCSV(classes);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', 'classes.csv');
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="page-wrapper">
      <Header/>
      <Sidebar/>
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Turmas</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item"><a href="index.html">Dashboard</a></li>
                <li className="breadcrumb-item active">Turmas</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Turmas</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                    <button
                        className="btn btn-outline-primary me-2"
                        onClick={downloadCSV}
                      >
                        <i className="fas fa-download"></i> Download
                      </button>
                      {/* <a href="/add-class" className="btn btn-primary"><i className="fas fa-plus"></i></a> */}
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Nome da Turma</th>
                        <th>Descrição</th>
                        <th>Idade</th>
                        <th>Mensalidade</th>
                        {/* <th className="text-end">Ação</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {classes.map((classe, index) => (
                        <tr key={classe.id}>
                          <td>{index + 1}</td>
                          <td><h2>{classe.name}</h2></td>
                          <td>{classe.description}</td>
                          <td>{classe.min_age}-{classe.max_age} anos</td>
                          <td>{classe.amount} MZN</td>
                          <td className="text-end">
                            <div className="actions">
                              {/* <a href={`/class-details/${classe.id}`} className="btn btn-sm bg-success-light me-2">
                                <i className="fa-regular fa-eye"></i>
                              </a> */}
                              {/* <a href={`/edit-class/${classe.id}`} className="btn btn-sm bg-danger-light">
                                <i className="fa-regular fa-pen-to-square"></i>
                              </a> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  );
};

export default ClassList;
