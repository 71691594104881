import React from 'react';
import { useAuth } from '../Context/AuthContext';
import icons8_bookmark_188 from '../assets/img/icons8-bookmark-94.png'
import icons8_graduate_94 from '../assets/img/icons8-graduate-94.png'
import icons8_graduation_cap_94 from '../assets/img/icons8-graduation-cap-94.png'
import icons8_pending_60 from '../assets/img/icons8-pending-60.png'
const Dashboard = () => {
  const { user } = useAuth();
  const widgets = [
    { title: 'Total de crianças matriculadas', count: '150', icon: icons8_graduation_cap_94 },
    { title: 'Inscrições pendentes', count: '15', icon: icons8_pending_60 },
    { title: 'Novas inscrições aprovadas', count: '15', icon: icons8_bookmark_188 },
    { title: 'Transferências recentes', count: '15', icon: icons8_graduate_94 }
  ];

  const charts = [
    { title: 'Distribuição de Alunos por Turmas', options: ['Turma A', 'Turma B', 'Turma C'], chartId: 'chart-class-distribution' },
    { title: 'Distribuição de Alunos por Faixa Etária', options: ['2-3 anos', '4-5 anos'], chartId: 'chart-age-distribution' }
  ];

  const recentRegistrations = [
    { id: 'REG1234', name: 'Maria Silva', date: '22/10/2024', status: 'Pendente' },
    { id: 'REG5678', name: 'João Pereira', date: '20/10/2024', status: 'Aprovada' },
    { id: 'REG9101', name: 'Ana Costa', date: '19/10/2024', status: 'Transferida' }
  ];

  return (
    <div className="page-wrapper">
      <div className="container-fluid p-5">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Bem-vindo {user.first_name}</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="index.html">Inicio</a>
                  </li>
                  <li className="breadcrumb-item active">Admin</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Overview Widgets */}
        <div className="row">
          {widgets.map((widget, idx) => (
            <div className="col-xl-3 col-sm-6 col-12 d-flex" key={idx}>
              <div className="card bg-comman w-100">
                <div className="card-body">
                  <div className="db-widgets d-flex justify-content-between align-items-center">
                    <div className="db-info">
                      <h6>{widget.title}</h6>
                      <h3>{widget.count}</h3>
                    </div>
                    <div className="db-icon">
                      <img src={widget.icon} alt="Dashboard Icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Charts for Student Distribution */}
        <div className="row">
          {charts.map((chart, idx) => (
            <div className="col-md-12 col-lg-6" key={idx}>
              <div className="card card-chart">
                <div className="card-header">
                  <div className="row align-items-center">
                    <div className="col-6">
                      <h5 className="card-title">{chart.title}</h5>
                    </div>
                    <div className="col-6">
                      <ul className="chart-list-out">
                        {chart.options.map((option, i) => (
                          <li key={i}>
                            <span className={`circle-${i === 0 ? 'blue' : 'green'}`}></span>
                            {option}
                          </li>
                        ))}
                        <li className="star-menus">
                          <a href="/">
                            <i className="fas fa-ellipsis-v"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <div id={chart.chartId}></div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Recent Registrations or Transfers */}
        <div className="row">
          <div className="col-xl-12">
            <div className="card flex-fill comman-shadow">
              <div className="card-header d-flex align-items-center">
                <h5 className="card-title">Histórico Recente de Inscrições ou Transferências</h5>
                <ul className="chart-list-out student-ellips">
                  <li className="star-menus">
                    <a href="/">
                      <i className="fas fa-ellipsis-v"></i>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center table-borderless table-striped">
                    <thead className="thead-light">
                      <tr>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Data</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentRegistrations.map((registration, idx) => (
                        <tr key={idx}>
                          <td>{registration.id}</td>
                          <td>{registration.name}</td>
                          <td>{registration.date}</td>
                          <td>{registration.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
