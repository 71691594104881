import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./assets/css/style.css";
import Home from "../src/Pages/Home";
import Fees from "../src/Pages/Fees";
import AddFees from "../src/Pages/AddFees";
import EditFees from "../src/Pages/EditFees";
import Employees from "./Pages/Employees";
import StudentsGrid from "./Pages/StudentDetails";
import AddStudent from "../src/Pages/AddStudent";
import EditStudent from "../src/Pages/EditStudent";
import Teachers from "../src/Pages/Teachers";
import TeachersGrid from "../src/Pages/TeachersGrid";
import EditTeacher from "../src/Pages/EditTeacher";
import Invoices from "../src/Pages/Invoices";
import Accounts from "../src/Pages/Accounts";
import AddSalary from "../src/Pages/AddSalary";
import Turmas from "../src/Pages/Turmas";
import AddTurma from "../src/Pages/AddTurma";
import EditTurma from "../src/Pages/EditTurma";
import ClassDetails from "../src/Pages/ClassDetails";
import Enrolment from "../src/Pages/Enrolment";
import { AuthProvider, useAuth } from "../src/Context/AuthContext";
import Login from "../src/Pages/Login";
import Settings from "./Pages/settings";
import Gallery from "./Pages/Gallery";
import useInactivityLogout from './hooks/useInactivityLogout'; 
import Profile from "./Pages/Profile";
import Extralessons from "./Pages/Extra-lessons";
import Subjects from "./Pages/Subject";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import PageNotFound from "./Pages/PageNotFound";
import PreRegistration from "./Pages/PreRegistration";
import EmployeeDetails from "./Pages/EmployeeDetails";
import AddEmployee from "./Pages/AddEmployee";
import AddSubject from "./Pages/AddSubject";
import Student from "./Pages/Student";
import GradeCurricular from "./Pages/GradeCurricular";
import StudentDetails from './Pages/StudentDetails';
import SpecialTreatment from "./Pages/SpecialTreatment";
import EditSchoolInfo from "./Pages/EditSchoolInfo";
const ProtectedRoute = ({ element }) => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

function App() {
  useInactivityLogout();
  
  return (
    <AuthProvider>
      <Router>
        
          <Routes>
          <Route path="/" element={<ProtectedRoute element={<Home />} />} />

            <Route path="/gallery" element={<Gallery />}/>
            <Route path="/settings/extra-lessons" element={<Extralessons />}/>
            <Route path="/profile" element={<Profile />}/>
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/auth/new-password/:tokenAndEmail" element={<ResetPassword />} />
            <Route path="/payments/transactions" element={<Fees />} />
            <Route path="/enrolment" element={<Enrolment />} />
            <Route path="/add-fees" element={<AddFees />} />
            <Route path="/edit-fees" element={<EditFees />} />
            <Route path="/settings/employees" element={<Employees />} />
            <Route path="/students-grid" element={<StudentsGrid />} />
            <Route path="/add-student" element={<AddStudent />} />
            <Route path="/edit-student/:studentId" element={<EditStudent />} />
            <Route path="/teachers" element={<Teachers />} />
            <Route path="/teachers-grid" element={<TeachersGrid />} />
            <Route path="/edit-teacher/:teacherId" element={<EditTeacher />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/add-salary" element={<AddSalary />} />
            <Route path="/class" element={<Turmas />} />
            <Route path="/add-class" element={<AddTurma />} />
            <Route path="/edit-class/:classId" element={<EditTurma />} />
            <Route path="/class-details" element={<ClassDetails />} />
            <Route path="/login" element={<Login />} />
            <Route path="/settings/centro-infantil" element={<Settings />} />
            <Route path="/pre-registrations" element={<PreRegistration />} />
            <Route path="/employee-details/:employeeId" element={<EmployeeDetails />} />
            <Route path="/settings/subjects" element={<Subjects />}/>
            <Route path="/add-subject" element={<AddSubject />}/>
            <Route path="/add-employee" element={<AddEmployee />}/>
            <Route path="/student" element={<Student />} />
            <Route path="/grade-curricular" element={<GradeCurricular />} />
            <Route path="/student/:studentId" element={<StudentDetails />} />
            <Route path="/special-treatments" element={<SpecialTreatment />}/>
            <Route path="/settings/edit/edit-school-info" element={<EditSchoolInfo />}/>
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        
      </Router>
    </AuthProvider>
  );
}

export default App;
