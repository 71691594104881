import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import "react-quill/dist/quill.snow.css";
import EditModal from "../Components/EditModal";
import "../assets/css/tabs.css";

const Settings = () => {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({});

  const fetchData = async () => {
    try {
      const response = await fetch(`${env.base_url}schools/${user.school.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const { data } = await response.json();
      setFormData(data);
    } catch (error) {
      console.error("Erro ao buscar dados do perfil:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="main-wrapper">
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <div className="page-sub-header">
                  <h3 className="page-title">Perfil do centro</h3>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="students.html">Configuracoes</a>
                    </li>
                    <li className="breadcrumb-item active">centro infantil</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="student-profile-head">
                    <div className="profile-bg-img">
                      <img
                        src={formData.full_cover_url}
                        alt="Profile"
                        style={{
                          width: "100%",
                          height: "318px",
                          objectFit: "cover", // Mantém o aspecto da imagem sem achatamento
                          
                        }}
                      />
                    </div>
                    <div className="row">
                      <div className="col-lg-6 col-md-6 d-flex align-items-center">
                        <div className="profile-user-box">
                          <div className="profile-user-img">
                            <img
                              src={formData.full_logo_url}
                              alt={formData.name}
                              className=""
                              style={{
                                objectFit: "cover",
                                width: "150px",
                                height: "150px",
                                borderRadius: "100px",
                                border: "10px solid #fff",
                              }}
                            />
                          </div>
                          <div className="names-profiles">
                            <h4 className="poppins-thin d-flex align-items-center">{formData.name}</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-2 col-md-4 d-flex align-items-center"></div>
                      <div className="col-lg-4 col-md-4 d-flex align-items-center">
                        <div className="follow-btn-group">
                          <a
                            href="/settings/edit/edit-school-info"
                            className="btn btn-info"
                          >
                            <i className="fa-solid fa-pen"></i>&nbsp; Editar
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="student-personals-grp">
                    <div className="card">
                      <div className="card-body">
                        <div className="heading-detail">
                          <h4 className="poppins-bold">
                            Informacao de contato:
                          </h4>
                        </div>
                        <div className="personal-activity">
                          <div className="personal-icons">
                            <i className="fa-regular fa-calendar-days"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Ano de fundação</h4>
                            <h5 className="poppins-thin">
                              {formData.foundation_year}
                            </h5>
                          </div>
                        </div>
                        <div className="personal-activity">
                          <div className="personal-icons">
                            <i className="fa-solid fa-phone"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Telefone</h4>
                            <h5 className="poppins-thin">{formData.phone}</h5>
                          </div>
                        </div>
                        <div className="personal-activity">
                          <div className="personal-icons">
                            <i className="fa-regular fa-envelope"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Email</h4>
                            <h5 className="poppins-thin email-wrap">
                              {formData.email}
                            </h5>
                          </div>
                        </div>
                        <div className="personal-activity">
                          <div className="personal-icons">
                            <i className="fa-solid fa-person-chalkboard"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Tipo de Ensino</h4>
                            <h5 className="poppins-thin">
                              {formData.education_type}
                            </h5>
                          </div>
                        </div>

                        <div className="personal-activity">
                          <div className="personal-icons">
                            <i className="fa-solid fa-language"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Idioma</h4>
                            <h5 className="poppins-thin">
                              {formData.languages}
                            </h5>
                          </div>
                        </div>
                        <div className="personal-activity mb-0">
                          <div className="personal-icons">
                            <i className="fa-solid fa-location-dot"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Endereco</h4>
                            <h5 className="poppins-thin">{formData.address}</h5>
                          </div>
                        </div>
                        <div className="personal-activity mt-3">
                          <div className="personal-icons">
                            <i className="fa-solid fa-city"></i>
                          </div>
                          <div className="views-personal">
                            <h4 className="poppins-medium">Provincia</h4>
                            <h5 className="poppins-thin">
                              {formData.province}
                            </h5>
                          </div>
                        </div>
                        <div className="personal-activity mt-3">
                          <div className="personal-icons">
                            <i className="fa-solid fa-tree"></i>
                          </div>
                          <div class="views-personal">
                            <h4 className="poppins-medium">Distrito</h4>
                            <h5 className="poppins-thin">
                              {formData.district}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="student-personals-grp">
                    <div className="card mb-0">
                      <div className="card-body">
                        <div className="heading-detail">
                          <h4 className="poppins-bold">Sobre a escola</h4>
                        </div>
                        <div className="hello-park">
                          <div className="form-group">
                            <p
                              dangerouslySetInnerHTML={{
                                __html: formData.description,
                              }}
                            />
                          </div>
                        </div>
                        <div className="heading-detail">
                          
                        </div>
                        <div className="hello-park">
                          <p
                            dangerouslySetInnerHTML={{
                              __html: formData.policies,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <EditModal show={showModal} handleClose={() => setShowModal(false)} />
      </div>
    </div>
  );
};

export default Settings;
