import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";

import { Button, Modal, Form } from "react-bootstrap";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";
import { useAuth } from "../Context/AuthContext";

const PlanoEnsino = () => {
  const { user } = useAuth();
  const [activities, setActivities] = useState([]);
  const [filteredActivities, setFilteredActivities] = useState([]); // Estado para atividades filtradas
  const [classes, setClasses] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [period, setPeriod] = useState("");
  const [ativityName, setAtivityName] = useState("");
  const [className, setClassName] = useState("");

  const [newActivityDescription, setNewActivityDescription] = useState(""); // Descrição da nova atividade
  const [newPeriod, setNewPeriod] = useState({
    period: "",
    start_time: "",
    end_time: "",
  }); // Estado para o período
  const [editDescription, setEditDescription] = useState("");
  const [loading, setLoading] = useState(false);

  // Modal para editar descrição da atividade
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditPeriodModal, setShowEditPeriodModal] = useState(false);

  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/activities`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setActivities(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchClasses = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/classes`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setClasses(data);
      } catch (error) {
        console.error("Erro ao buscar os dados das turmas:", error);
      }
    };

    fetchActivities();
    fetchClasses();
  }, [user]);

  const handleShowModal = (activity) => {
    setSelectedActivity(activity);
    showEditPeriodModal(true);
  };

  const handleCloseModal = () => {
    setShowEditPeriodModal(false);
  };

  // Abrir modal de edição
  const handleShowEditModal = (activity) => {
    setSelectedActivity(activity);
    setEditDescription(activity.description);
    setShowEditModal(true);
  };

  // Fechar modal de edição
  const handleCloseEditModal = () => {
    setShowEditModal(false);
  };
  const handleCloseEditPeriodModal = () => {
    setShowEditPeriodModal(false);
  };

  const handleShowEditPeriodModal = (activity) => {
    setSelectedPeriod(activity.schedule);
    setEndTime(activity.schedule.end_time);
    setStartTime(activity.schedule.start_time);
    setPeriod(activity.schedule.period);
    setSelectedClass(activity.schedule.school_class_id); // Define a turma existente ao abrir o modal
    setShowEditPeriodModal(true);
  };

  // Enviar a descrição atualizada para a API
  const handleEditSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities/${selectedActivity.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: editDescription,
          }),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        setActivities((prevActivities) =>
          prevActivities.map((activity) =>
            activity.id === selectedActivity.id
              ? { ...activity, description: editDescription }
              : activity
          )
        );
        setLoading(false);
        handleCloseEditModal();
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
      setLoading(false);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities/${selectedActivity.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({}),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        // Atualizar a lista de atividades com a nova descrição
        setActivities((prevActivities) =>
          prevActivities.map((activity) =>
            activity.id === selectedActivity.id
              ? { ...activity, description: editDescription }
              : activity
          )
        );

        handleCloseEditModal();
      } else {
        ToastAlert({ icon: "error", title: "success", text: errors });
      }
    } catch (error) {
      console.error("Erro ao fazer a requisição:", error);
    }
  };

  const handleEditPeriodSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities/${selectedActivity.id}/schedules/${selectedActivity.schedule.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            period: period,
            start_time: startTime,
            end_time: endTime,
            school_class_id: selectedClass, // Envia apenas a turma selecionada
          }),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({
          icon: "success",
          title: "Período atualizado",
          text: message,
        });
        setActivities((prevActivities) =>
          prevActivities.map((activity) =>
            activity.id === selectedActivity.schedule.id
              ? { ...activity, period: period, start_time: startTime, end_time: endTime, school_class_id: selectedClass }
              : activity
          )
        );
        setLoading(false);
        handleCloseEditPeriodModal(); // Fecha o modal após o sucesso
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao atualizar o período:", error);
      ToastAlert({ msg: "Erro ao atualizar o período.", msgType: "error" });
      setLoading(false);
    }
  };

  const handleAddActivity = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/activities`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            description: newActivityDescription,
            schedules: [
              {
                period: newPeriod.period,
                start_time: newPeriod.start_time,
                end_time: newPeriod.end_time,
                school_class_id: selectedClass,
              },
            ],
          }),
        }
      );

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({
          icon: "success",
          title: "Atividade adicionada",
          text: message,
        });

        setLoading(false);

        handleCloseModal(); // Fechar o modal após adicionar a atividade
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao adicionar atividade:", error);
    }
  };

  useEffect(() => {
    // Função para filtrar as atividades
    const filterActivities = () => {
      let filtered = activities;

      if (ativityName) {
        filtered = filtered.filter((activity) =>
          activity.description.toLowerCase().includes(ativityName.toLowerCase())
        );
      }

      if (className) {
        filtered = filtered.filter((activity) =>
          activity.schedule.name.toUpperCase().includes(className.toUpperCase())
        );
      }

      if (period) {
        filtered = filtered.filter((activity) =>
          activity.schedule.period.toUpperCase().includes(period.toUpperCase())
        );
      }

      setFilteredActivities(filtered); // Atualiza o estado com as atividades filtradas
    };

    filterActivities(); // Chama a função sempre que os inputs mudam
  }, [ativityName, className, period, activities]);

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Plano de Ensino</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Plano de Ensino</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="student-group-form">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por Nome da atividade..."
                  value={ativityName}
                  onChange={(e) => setAtivityName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <select
                  className="form-control"
                  placeholder="Buscar por turma..."
                  value={className}
                  onChange={(e) => setClassName(e.target.value)}
                >
                  <option value="">Filtrar por Turma</option>
                  {classes.map((classItem) => (
                    <option key={classItem.id} value={classItem.name}>
                      {classItem.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value="">Filtrar por Periodo</option>
                  <option value="Manhã">Manhã</option>
                  <option value="Meio-dia">Meio-dia</option>
                  <option value="Tarde">Tarde</option>
                  <option value="Noite">Noite</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="search-student-btn">
                <button type="button" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <button className="btn btn-outline-primary me-2">
                        <i className="fas fa-download"></i> Download
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => setShowAddModal(true)}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Atividade</th>
                        <th>Turma</th>
                        <th>Período</th>
                        <th>Horário</th>
                        <th className="text-end">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredActivities.map((activity, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{activity.description}</td>
                          <td>{activity.schedule.name}</td>
                          <td>{activity.schedule.period}</td>
                          <td>
                            {activity.schedule.start_time}-
                            {activity.schedule.end_time}
                          </td>
                          <td className="text-end">
                            <div className="actions">
                              {/* <Button
                                className="btn btn-sm btn-success me-1"
                                title="Ver Detalhes"
                                onClick={() => handleShowModal(activity)}
                              >
                                <i className="fas fa-eye"></i>
                              </Button> */}
                              <Button
                                className="btn btn-sm btn-warning me-1"
                                title="Editar descricao"
                                onClick={() => handleShowEditModal(activity)}
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ color: "white" }}
                                ></i>
                              </Button>
                              <Button
                                title="Editar Periodo da atividade"
                                className="btn btn-sm btn-success me-1"
                                onClick={() =>
                                  handleShowEditPeriodModal(activity)
                                }
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ color: "white" }}
                                ></i>
                              </Button>
                              <Button
                                className="btn btn-sm btn-danger"
                                title="Deletar"
                                onClick={() => handleDeleteSubmit(activity)}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal de Edição de Atividade */}
        <Modal
          centered
          size="lg"
          show={showEditModal}
          onHide={handleCloseEditModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Atividade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formActivityDescription">
                <Form.Label>Descrição</Form.Label>
                <Form.Control
                  type="text"
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                />
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handleEditSubmit}
              >
                {loading ? "Editando..." : "Editar"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Modal de Edição de Período */}
        <Modal
          centered
          size="lg"
          show={showEditPeriodModal}
          onHide={handleCloseEditPeriodModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Editar Período</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formPeriod">
                <Form.Label>Período</Form.Label>
                <Form.Control
                  as={"select"}
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option value="">Selecione o período</option>
                  <option value="Manhã">Manhã</option>
                  <option value="Meio-dia">Meio-dia</option>
                  <option value="Tarde">Tarde</option>
                  <option value="Noite">Noite</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="formStartTime">
                <Form.Label>Hora de Início</Form.Label>
                <Form.Control
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formEndTime">
                <Form.Label>Hora de Fim</Form.Label>
                <Form.Control
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="formClass">
                <Form.Label>Turma</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedClass}
                  onChange={(e) => setSelectedClass(e.target.value)}
                >
                  <option value="">Selecione a Turma</option>
                  {classes.map((classItem) => (
                    <option key={classItem.id} value={classItem.id}>
                      {classItem.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Button
                variant="primary"
                className="mt-3"
                onClick={handleEditPeriodSubmit}
              >
                {loading ? "Editando..." : "Editar"}
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        {/* Modal para adicionar nova atividade e período */}
        <Modal
          size="lg"
          centered
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Adicionar Nova Atividade</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="activityDescription">
                <Form.Label>Descrição da Atividade</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Descrição"
                  value={newActivityDescription}
                  onChange={(e) => setNewActivityDescription(e.target.value)}
                />
              </Form.Group>
              <Form.Group controlId="classSelect">
                <Form.Label>Selecione a Classe</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedClass}
                  onChange={(e) => setSelectedClass(e.target.value)}
                >
                  <option value="">Selecione uma classe</option>
                  {classes.map((classe) => (
                    <option key={classe.id} value={classe.id}>
                      {classe.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="period">
                <Form.Label className="mt-3">Período</Form.Label>
                <Form.Control
                  as="select"
                  value={newPeriod.period}
                  onChange={(e) =>
                    setNewPeriod((prev) => ({
                      ...prev,
                      period: e.target.value,
                    }))
                  }
                >
                  <option value="">Selecione o período</option>
                  <option value="Manhã">Manhã</option>
                  <option value="Meio-dia">Meio-dia</option>
                  <option value="Tarde">Tarde</option>
                  <option value="Noite">Noite</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="startTimeInput">
                <Form.Label>Hora de Início</Form.Label>
                <Form.Control
                  type="time"
                  value={newPeriod.start_time}
                  onChange={(e) =>
                    setNewPeriod((prev) => ({
                      ...prev,
                      start_time: e.target.value,
                    }))
                  }
                />
              </Form.Group>
              <Form.Group controlId="endTimeInput">
                <Form.Label>Hora de Término</Form.Label>
                <Form.Control
                  type="time"
                  value={newPeriod.end_time}
                  onChange={(e) =>
                    setNewPeriod((prev) => ({
                      ...prev,
                      end_time: e.target.value,
                    }))
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowAddModal(false)}>
              Fechar
            </Button>
            <Button variant="primary" onClick={handleAddActivity}>
              {loading ? "Adicionando..." : "Adicionar"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default PlanoEnsino;
