import React, { useEffect, useState } from "react";
import finalize_Mainicon from "../assets/img/Finalize_MainIcon.png";
import Finalize_LongDefault from "../assets/img/Finalize_LongDefault.png"
import logo_small from "../assets/img/logo.png";
import header_icon_05 from "../assets/img/icons/header-icon-05.svg";
import { useAuth } from "../Context/AuthContext";
import { useNavigate } from "react-router-dom";
import env from "../utils/env";

const Header = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [userProfile, setUserProfile] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  useEffect(() => {
    // Função para buscar dados do perfil
    const token = localStorage.getItem("token");
    const fetchUserProfile = async () => {
      try {
        const response = await fetch(`${env.base_url}accounts/${user.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        });
        if (response.ok) {
          const { data } = await response.json();
          setUserProfile(data);
        } else {
          setError("Erro ao buscar perfil");
        }
      } catch (error) {
        setError("Erro ao buscar perfil");
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, [user.id]);

  useEffect(() => {
    const mobileBtn = document.getElementById("mobile_btn");
    const sidebar = document.querySelector(".sidebar");

    if (mobileBtn && sidebar) {
      const toggleSidebar = (e) => {
        e.preventDefault();
        sidebar.classList.toggle("abrir"); // Alterna a classe 'abrir'
      };

      mobileBtn.addEventListener("click", toggleSidebar);

      // Limpeza do eventListener ao desmontar o componente
      return () => {
        mobileBtn.removeEventListener("click", toggleSidebar);
      };
    }
  }, []);

  return (

    <div className="header">
      <div className="header-left">
        <a href="/" className="logo">
          <img src={Finalize_LongDefault} alt="Logo" />
        </a>
        
      </div>
      
     

      <div className="menu-toggle" style={{display: 'none'}}>
        <a href="/" id="toggle_btn">
          <i className="fas fa-bars"></i>
        </a>
      </div>

      {/* <div className="top-nav-search">
          <form>
            <input type="text" className="form-control" placeholder="Search here" />
            <button className="btn" type="submit">
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div> */}

      <a className="mobile_btn" id="mobile_btn" href="/">
        <i className="fas fa-bars"></i>
      </a>

      <ul className="nav user-menu">
        <li className="nav-item dropdown noti-dropdown language-drop me-2">
          <div className="dropdown-menu">
            <div className="noti-content">
              <div>
                <a className="dropdown-item" href="index.html">
                  <i className="flag flag-lr me-2"></i>English
                </a>
                <a className="dropdown-item" href="index.html">
                  <i className="flag flag-bl me-2"></i>Francais
                </a>
                <a className="dropdown-item" href="index.html">
                  <i className="flag flag-cn me-2"></i>Turkce
                </a>
              </div>
            </div>
          </div>
        </li>

        <li className="nav-item dropdown noti-dropdown me-2">
          <a
            href="index.html#"
            className="dropdown-toggle nav-link header-nav-list"
            data-bs-toggle="dropdown"
          >
            <img src={header_icon_05} alt="Notifications" />
          </a>
          <div className="dropdown-menu notifications">
            <div className="topnav-dropdown-header">
              <span className="notification-title">Notifications</span>
              <a href="index.html" className="clear-noti">
                {" "}
                Clear All{" "}
              </a>
            </div>
            <div className="noti-content">
              <ul className="notification-list">
                <li className="notification-message">
                  <a href="index.html#">
                    <div className="media d-flex">
                      <span className="avatar avatar-sm flex-shrink-0">
                        <img
                          src="../src/assets/img/profiles/avatar-02.jpg"
                          className="avatar-img rounded-circle"
                          alt="User"
                        />
                      </span>
                      <div className="media-body flex-grow-1">
                        <p className="noti-details">
                          <span className="noti-title">Carlson Tech</span> has
                          approved
                          <span className="noti-title">your estimate</span>
                        </p>
                        <p className="noti-time">
                          <span className="notification-time">4 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="notification-message">
                  <a href="index.html#">
                    <div className="media d-flex">
                      <span className="avatar avatar-sm flex-shrink-0">
                        <img
                          className="avatar-img rounded-circle"
                          alt="Image1"
                          src="../src/assets/img/profiles/avatar-11.jpg"
                        />
                      </span>
                      <div className="media-body flex-grow-1">
                        <p className="noti-details">
                          <span className="noti-title">
                            International Software Inc
                          </span>
                          has sent you a invoice in the amount of
                          <span className="noti-title">$218</span>
                        </p>
                        <p className="noti-time">
                          <span className="notification-time">6 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="notification-message">
                  <a href="/">
                    <div className="media d-flex">
                      <span className="avatar avatar-sm flex-shrink-0">
                        <img
                          className="avatar-img rounded-circle"
                          alt="User2"
                          src="../src/assets/img/profiles/avatar-17.jpg"
                        />
                      </span>
                      <div className="media-body flex-grow-1">
                        <p className="noti-details">
                          <span className="noti-title">John Hendry</span> sent a
                          cancellation request
                          <span className="noti-title">Apple iPhone XR</span>
                        </p>
                        <p className="noti-time">
                          <span className="notification-time">8 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="notification-message">
                  <a href="/">
                    <div className="media d-flex">
                      <span className="avatar avatar-sm flex-shrink-0">
                        <img
                          className="avatar-img rounded-circle"
                          alt="User3"
                          src="../src/assets/img/profiles/avatar-13.jpg"
                        />
                      </span>
                      <div className="media-body flex-grow-1">
                        <p className="noti-details">
                          <span className="noti-title">
                            Mercury Software Inc
                          </span>
                          added a new product
                          <span className="noti-title">Apple MacBook Pro</span>
                        </p>
                        <p className="noti-time">
                          <span className="notification-time">12 mins ago</span>
                        </p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <div className="topnav-dropdown-footer">
              <a href="index.html#">View all Notifications</a>
            </div>
          </div>
        </li>

        {/* <li className="nav-item zoom-screen me-2">
            <a href="index.html#" className="nav-link header-nav-list win-maximize">
              <img src={header_icon_04} alt="Zoom" />
            </a>
          </li> */}

        <li className="nav-item dropdown has-arrow new-user-menus">
          <a
            href="/"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
          >
            <div className="user-img">
              <img
                className="rounded-circle"
                src={user.full_picture_url}
                width=""
                alt="Ryan Taylor"
              />
              <div className="user-text">
                <h6>
                  {userProfile.first_name} {userProfile.last_name}
                </h6>
                <p className="text-muted mb-0">Administrator</p>
              </div>
            </div>
          </a>
          <div className="dropdown-menu">
            <div className="user-header">
              <div className="avatar avatar-sm">
                <img
                  src={user.full_picture_url}
                  alt="User3"
                  className="avatar-img"
                />
              </div>
              <div className="user-text">
                <h6>
                  {userProfile.first_name} {userProfile.last_name}
                </h6>
                <p className="text-muted mb-0">Administrator</p>
              </div>
            </div>
            <a className="dropdown-item" href="/profile">
              My Profile
            </a>
            <a className="dropdown-item" href="/inbox">
              Inbox
            </a>
            <a className="dropdown-item" href="/login" onClick={handleLogout}>
              Logout
            </a>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Header;
