import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import avatar from "../assets/img/profiles/avatar-01.jpg";

const EmployeeDetails = () => {
  const { employeeId } = useParams();
  const { user } = useAuth();
  const [employee, setEmployee] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    address: "",
    data_of_birth: "",
    full_picture_url: "",
    status: "",
    other_position: "",
    position: "",
    check_in_time: "",
    check_out_time: "",
    contract_type: "",
    termination_date: "",
    district_id: "",
    salary: "",
    hire_date: "",
    salary_type: "",
    contracts: [],
  });

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/employees/${employeeId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setEmployee(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchEmployee();
  }, [employeeId, user]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  if (!employee) {
    return <div>Funcionário não encontrado</div>;
  }

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Detalhes do Colaborador</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="students.html">Colaborador</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Detalhes do Colaborador
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="student-profile-head">
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="profile-user-box">
                        <div className="profile-user-img">
                          <img
                            src={employee.full_picture_url || avatar}
                            alt="Avatar"
                            classNameName="img-fluid rounded-circle"
                          />
                        </div>
                        <div className="names-profiles">
                          <h4>
                            {employee.first_name} {employee.last_name}
                          </h4>
                          <h5>{employee.email}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4">
                <div className="student-personals-grp">
                  <div className="card">
                    <div className="card-body">
                      <div className="heading-detail">
                        <h4>Informações Pessoais:</h4>
                      </div>
                      <div className="personal-activity">
                        <div className="personal-icons">
                          <i className="feather-user"></i>
                        </div>
                      </div>

                      <div className="personal-activity">
                        <div className="personal-icons">
                          <i className="feather-calendar"></i>
                        </div>
                        <div className="views-personal">
                          <h4>Genero</h4>
                          <h5>{employee.gender}</h5>
                        </div>
                      </div>
                      <div className="personal-activity">
                        <div className="personal-icons">
                          <i className="feather-phone-call"></i>
                        </div>
                        <div className="views-personal">
                          <h4>Contacto</h4>
                          <h5>{employee.phone}</h5>
                        </div>
                      </div>

                      <div className="personal-activity mb-0">
                        <div className="personal-icons">
                          <i className="feather-map-pin"></i>
                        </div>
                        <div className="views-personal">
                          <h4>Endereço</h4>
                          <h5>{employee.address}</h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="student-personals-grp">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="heading-detail">
                        <h4>Informações Profissionais:</h4>
                      </div>
                      <p>
                        {employee.contracts.length > 0
                          ? formatDate(employee.contracts[0].hire_date)
                          : "N/A"}
                      </p>
                      <div className="educate-year">
                        <h4>Cargo:</h4>
                        <p>
                          {employee.contracts.length > 0
                            ? employee.contracts[0].position
                            : "N/A"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8">
                <div className="student-personals-grp">
                  <div className="card mb-0">
                    <div className="card-body">
                      <div className="hello-park">
                        <h5>Informacoes de contrato</h5>
                        <div className="educate-year">
                          <h6>Tipo de contrato</h6>
                          <p>
                            {employee.contracts.length > 0
                              ? employee.contracts[0].contract_type
                              : "N/A"}
                          </p>
                        </div>
                        <div className="educate-year">
                          <h6>Salário</h6>
                          <p>
                            {employee.contracts.length > 0
                              ? employee.contracts[0].salary
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default EmployeeDetails;
