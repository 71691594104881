import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { Modal, Button, Form } from "react-bootstrap";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";

const Enrolment = () => {
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const { user } = useAuth();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [comments, setComments] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [enrolmentData, setEnrolmentData] = useState([]);

  useEffect(() => {
    const fetchEnrolments = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/enrolments`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erro ao buscar inscrições");
        }

        const data = await response.json();
        setEnrolmentData(data.data);
        setUpdatedStatus();
      } catch (error) {
        console.error("Erro ao buscar inscrições:", error);
      }
    };

    fetchEnrolments();
  }, [user.school.id]);

  // API de atualizar o status do aluno
  const updateStudentStatus = async () => {
    if (!selectedStudent) return;

    setLoading(true);

    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/enrolments/${selectedStudent.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ status: updatedStatus, comments }), // Incluindo comentários se necessário
        }
      );

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: message });
        setUpdatedStatus();
        setLoading(false);
        closeModal(); // Fechar o modal após atualização
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
      setLoading(false);
    }
  };

  const filteredStudents = enrolmentData.filter(
    (student) =>
      student.student.name.toLowerCase().includes(searchName.toLowerCase()) &&
      student.created_at.includes(searchDate) &&
      (statusFilter === "" || student.status === statusFilter)
  );

  // Função para converter dados para CSV e iniciar o download
  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "#",
      "Nome do Educando",
      "Data de Inscrição",
      "Status",
      "Nome do Encarregado",
    ];
    csvRows.push(headers.join(","));

    filteredStudents.forEach((student, index) => {
      const row = [
        index + 1,
        student.student.name,
        formatDate(student.created_at),
        student.status,
        `${student.student.parent.first_name} ${student.student.parent.last_name}`,
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "inscricoes.csv";
    link.click();
  };

  const openModal = (student) => {
    setSelectedStudent(student);
    setComments(""); // Reset comments when opening the modal
    setUpdatedStatus(student.status); // Set initial status value for select
  };

  const closeModal = () => {
    setSelectedStudent(null);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "ativa":
        return "btn-success";
      case "desistiu":
        return "btn-danger";
      case "concluída":
        return "btn-primary";
      case "pendente":
        return "btn-warning";
      case "cancelada":
        return "btn-secondary";
      case "suspensa":
        return "btn-dark";
      default:
        return "btn-light";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Inscrições Recebidas</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/enrolment">Inscrições</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Visualizar Inscrições
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="student-group-form">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por Nome do Educando..."
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Buscar por Data de Inscrição..."
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">Filtrar por Status</option>
                  <option value="ativa">Ativa</option>
                  <option value="desistiu">Desistiu</option>
                  <option value="concluída">Concluída</option>
                  <option value="pendente">Pendente</option>
                  <option value="cancelada">Cancelada</option>
                  <option value="suspensa">Suspensa</option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="search-student-btn">
                <button type="button" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table comman-shadow">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Lista de Inscrições</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <a
                        href="/students"
                        className="btn btn-outline-gray me-2 active"
                      >
                        <i className="fa-solid fa-list-ul"></i>
                      </a>
                      <a
                        href="/students-grid"
                        className="btn btn-outline-gray me-2"
                      >
                        <i className="fa-solid fa-grip"></i>
                      </a>
                      <button
                        className="btn btn-outline-primary me-2"
                        onClick={downloadCSV}
                      >
                        <i className="fas fa-download"></i> Download
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Nome do Educando</th>
                        <th>Data de Inscrição</th>
                        <th>Status</th>
                        <th>Nome do Encarregado</th>
                        <th>Turma</th>
                        <th>Sala</th>
                        <th>Mensalidade</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredStudents.map((student, index) => (
                        <tr key={index}>
                          {/* coloca index ai */}
                          <td>{index + 1}</td>
                          <td>
                            <h2 className="table-avatar">
                              <a href="student-details.html">
                                {student.student.name}
                              </a>
                            </h2>
                          </td>
                          <td>{student.created_at}</td>
                          <td>
                            <Button
                              className={`text-white ${getStatusClass(
                                student.status
                              )}`}
                            >
                              {student.status}
                            </Button>
                          </td>
                          <td>
                            {student.student.parent.first_name}{" "}
                            {student.student.parent.last_name}
                          </td>
                          <td>{student.class.name}</td>
                          <td>{student.class.classroom}</td>
                          <td>{student.monthly_tuition_fee}</td>
                          <td className="text-end">
                            <button
                              className="btn btn-sm btn-outline-primary"
                              title="ver detalhes"
                              onClick={() => openModal(student)}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal size="lg" centered show={selectedStudent !== null} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes da Inscrição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedStudent && (
              <>
                <h5>{selectedStudent.student.name}</h5>
                <p>
                  {" "}
                  <b>Data de Inscrição:</b>{" "}
                  {formatDate(selectedStudent.created_at)}
                </p>
                <p>
                  <b>Data de nascimento:</b>{" "}
                  {selectedStudent.student.birth_date}
                </p>
                <p>
                  <b>Idade:</b>{" "}
                  {selectedStudent.student.age}
                </p>
                <p>
                  <b>Encarregado:</b>{" "}
                  {selectedStudent.student.parent.first_name}{" "}
                  {selectedStudent.student.parent.last_name}
                </p>
                <p>
                  <b>Email do Encarregado:</b>{" "}
                  {selectedStudent.student.parent.email}
                </p>
                <p>
                  <b>Telefone do Encarregado:</b>{" "}
                  {selectedStudent.student.parent.phone}
                </p>
                <p>
                  <b>Genero:</b> {selectedStudent.student.gender}
                </p>
                <p>
                  <b>Classe:</b> {selectedStudent.class.classroom}
                </p>
                <p>
                  <b>Turma:</b> {selectedStudent.class.name}
                </p>
                <p>
                  <b>Mensalidade:</b> {selectedStudent.monthly_tuition_fee}
                </p>
                
                <Form.Group>
                  <Form.Label>Comentários</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={updatedStatus}
                    onChange={(e) => setUpdatedStatus(e.target.value)}
                  >
                    <option value="">Selecione...</option>
                    <option value="ativa">Ativa</option>
                    <option value="desistiu">Desistiu</option>
                    <option value="concluída">Concluída</option>
                    <option value="pendente">Pendente</option>
                    <option value="cancelada">Cancelada</option>
                    <option value="suspensa">Suspensa</option>
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              disabled={loading}
              style={{ marginRight: "10px" }}
              onClick={updateStudentStatus}
            >
              {loading ? "Atualizando..." : "Atualizar"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Enrolment;
