import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import ReactQuill from "react-quill";
import { useAuth } from "../Context/AuthContext";
import env from "../utils/env";
import ToastAlert from "../utils/ToastAlert";
import "react-quill/dist/quill.snow.css"; // Importa o estilo do Quill
import "../assets/css/tabs.css";
import "../assets/css/map.css";

const EditModal = ({ show, handleClose }) => {
  const [key, setKey] = useState("pictures"); // Gerenciando as tabs
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({ description: "", policies: "" });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDescriptionChange = (value) => {
    if (value.length <= 2000) {
      setError((prevError) => ({ ...prevError, description: "" })); // Remove error
      setFormData((prevData) => ({ ...prevData, description: value }));
    } else {
      setError((prevError) => ({
        ...prevError,
        description: "Máximo de 2000 caracteres",
      })); // Set error
      setFormData((prevData) => ({
        ...prevData,
        description: value.slice(0, 2000),
      })); // Limit to 2000
    }
  };

  const handlePoliciesChange = (value) => {
    if (value.length <= 500) {
      setError((prevError) => ({ ...prevError, policies: "" })); // Remove error
      setFormData((prevData) => ({ ...prevData, policies: value }));
    } else {
      setError((prevError) => ({
        ...prevError,
        policies: "Máximo de 2000 caracteres",
      })); // Set error
      setFormData((prevData) => ({
        ...prevData,
        policies: value.slice(0, 2000),
      })); // Limit to 2000
    }
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (
      !["image/jpeg", "image/png"].includes(file.type) ||
      file.size > 2 * 1024 * 1024
    ) {
      alert("O arquivo deve ser JPEG ou PNG e não pode exceder 2 MB.");
      return;
    }
    setFormData({
      ...formData,
      logo: file,
    });
    const reader = new FileReader();
    reader.onloadend = () => {
      setFormData((prevData) => ({
        ...prevData,
        full_logo_url: reader.result,
      }));
    };
    reader.readAsDataURL(file);
  };
  const handleCoverChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificação do tipo de arquivo e tamanho
      if (
        !["image/jpeg", "image/png"].includes(file.type) ||
        file.size > 2 * 1024 * 1024
      ) {
        alert("O arquivo deve ser JPEG ou PNG e não pode exceder 2 MB.");
        return;
      }

      setFormData({
        ...formData,
        cover: file,
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setFormData((prevData) => ({
          ...prevData,
          full_cover_url: reader.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    return `${hours}:${minutes}:00`; // Adiciona os segundos como 00
  };

  const updateDetails = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const formattedData = {
        ...formData,
        open_time: formatTime(formData.open_time),
        close_time: formatTime(formData.close_time),
      };

      const response = await fetch(`${env.base_url}schools/${formData.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify(formattedData),
      });

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await fetch(`${env.base_url}schools/${user.school.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
      const { data } = await response.json();
      console.log(data);
      setFormData(data);
    } catch (error) {
      console.error("Erro ao buscar dados do perfil:", error);
    }
  };
  const uploadLogo = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("logo", formData.logo);

      const response = await fetch(
        `${env.base_url}schools/logo/${formData.id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: formDataToSend,
        }
      );

      const { message, errors } = await response.json();
      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
      }
    } catch (error) {
      console.error("Erro ao atualizar endereço:", error);
      ToastAlert({
        msg: "Erro ao atualizar o endereço.",
        msgType: "error",
        timeout: 3000,
      });
    } finally {
      setLoading(false);
    }
  };
  const uploadCover = async () => {
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("cover", formData.cover);

      const response = await fetch(
        `${env.base_url}schools/${formData.id}/cover`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            Accept: "application/json",
          },
          body: formDataToSend,
        }
      );

      const data = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: data.message });

        // Verifica se a resposta tem a URL da nova capa e retorna
        if (data.cover_url) {
          return data.cover_url;
        }

        return null;
      } else {
        ToastAlert({ icon: "error", title: "error", text: data.errors });
        return null;
      }
    } catch (error) {
      console.error("Erro ao atualizar capa:", error);
      return null;
    }
  };

  const updateProfile = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    setLoading(true);

    try {
      let logoUrl = formData.full_logo_url;
      let coverUrl = formData.full_cover_url;

      // Upload do logo se necessário
      if (formData.logo) {
        const uploadedLogoUrl = await uploadLogo();
        if (uploadedLogoUrl) {
          logoUrl = uploadedLogoUrl;
        } else {
          setLoading(false);
          return;
        }
      }

      // Upload da capa se necessário
      if (formData.cover) {
        const uploadedCoverUrl = await uploadCover();
        if (uploadedCoverUrl) {
          coverUrl = uploadedCoverUrl;
        } else {
          setLoading(false);
          return;
        }
      }

      const updateData = {
        ...formData,
        full_logo_url: logoUrl,
        full_cover_url: coverUrl,
        logo: undefined,
        cover: undefined,
      };

      const response = await fetch(`${env.base_url}schools/${user.school.id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
        },
        body: JSON.stringify(updateData),
      });

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
      }
      setLoading(false);
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
      setLoading(false);
    }
  };

  // const fetchProvincesData = async () => {
  //   try {
  //     const response = await fetch(`${env.base_url}provinces`, {
  //       method: "GET",
  //       mode: "cors",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         Accept: "application/json",
  //       },
  //     });

  //     if (!response.ok) {
  //       throw new Error(`Erro: ${response.status}`);
  //     }

  //     const { data } = await response.json();

  //     setProvinces(data);
  //   } catch (error) {
  //     console.error("Erro ao buscar dados das províncias:", error);
  //   }
  // };

  // const fetchDistrictsData = async (provinceId) => {
  //   try {
  //     const response = await fetch(
  //       `${env.base_url}districts?province_id=${provinceId}`,
  //       {
  //         method: "GET",
  //         mode: "cors",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //           Accept: "application/json",
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error(`Erro: ${response.status}`);
  //     }

  //     const { data } = await response.json();
  //     setDistricts(data);
  //   } catch (error) {
  //     console.error("Erro ao buscar dados dos distritos:", error);
  //   }
  // };

  const handleAddressChange = async (e) => {
    const query = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      address: query,
    }));

    if (query.length > 3) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/search?format=jsonv2&addressdetails=1&q=${encodeURIComponent(
            query
          )}&countrycodes=MZ&dedupe=0`
        );
        const data = await response.json();
        setSuggestions(data);
      } catch (error) {
        console.error("Erro ao buscar sugestões de endereço:", error);
      }
    }
  };

  const updateAddress = async (e) => {
    e.preventDefault();
    setLoading(true);

    console.log("Dados enviados:", formData); // Adicione este log para verificar o conteúdo

    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/address`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(formData), // Enviar os dados ajustados
        }
      );

      const { message, errors } = await response.json();
      if (response.ok) {
        ToastAlert({ icon: "success", title: "Sucesso", text: message });
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
      }
    } catch (error) {
      console.error("Erro ao atualizar endereço:", error);
      ToastAlert({
        msg: "Erro ao atualizar o endereço.",
        msgType: "error",
        timeout: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    const {
      city,
      district,
      state_district,
      state,
      county,
      town,
      village,
      suburb,
      neighbourhood,
      municipality,
    } = suggestion.address;

    const selectedProvince = state || city || county || village || town || "";
    const selectedDistrict =
      district ||
      state_district ||
      city ||
      suburb ||
      neighbourhood ||
      municipality ||
      "";

    // Verificação se province e district contêm apenas letras
    const isProvinceValid = /^[a-zA-Z\s]+$/.test(selectedProvince);
    const isDistrictValid = /^[a-zA-Z\s]+$/.test(selectedDistrict);

    setFormData((prevState) => ({
      ...prevState,
      address: suggestion.display_name,
      latitude: suggestion.lat,
      longitude: suggestion.lon,
      // Atribuindo os valores somente se forem válidos
      province: isProvinceValid ? selectedProvince : "",

      district: isDistrictValid ? selectedDistrict : "",
    }));

    setSuggestions([]); // Limpa as sugestões após a seleção
  };

  useEffect(() => {
    fetchData();
  }, []);

  function stripTags(html) {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  }

  return (
    <Modal
      centered
      size={window.innerWidth < 576 ? "sm" : "lg"}
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>Editar Perfil</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="nav-tabs poppins-semibold"
          style={{ padding: "20px", border: "none" }}
        >
          <Tab eventKey="pictures" title="images">
            <div className="settings-form">
              <div className="card" style={{ border: "1px solid #ccc" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-start ml-4">
                        {/* A imagem fica sozinha */}
                        <img
                          src={formData.full_logo_url}
                          alt={formData.name}
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "100px",
                            height: "100px",
                            borderRadius: "100px",
                            border: "1px solid #ccc",
                          }}
                        />

                        {/* O botão (label/input) fica ao lado da imagem */}
                        <div style={{ marginLeft: "10px", marginTop: "15px" }}>
                          <label
                            htmlFor="logo"
                            className="btn label"
                            style={{
                              backgroundColor: "#3D5EE1",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              color: "white",
                              border: "none",
                              cursor: "pointer",
                              marginBottom: "10px",
                            }}
                          >
                            Mudar Logo
                          </label>

                          <input
                            type="file"
                            id="logo"
                            name="logo"
                            accept="image/*"
                            onChange={handleLogoChange}
                            style={{ display: "none" }}
                          />

                          {/* O parágrafo vai abaixo do label/input */}
                          <p style={{ marginTop: "5px", fontSize: "12px" }}>
                            Deve ser JPEG, PNG e não pode exceder 2 MB.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card" style={{ border: "1px solid #ccc" }}>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="d-flex align-items-start ml-4">
                        {/* A imagem fica sozinha */}
                        <img
                          src={formData.full_cover_url}
                          alt={formData.name}
                          className="img-fluid"
                          style={{
                            objectFit: "cover",
                            width: "200px",
                            height: "100px",
                            borderRadius: "12px",
                            border: "1px solid #ccc",
                          }}
                        />

                        {/* O botão (label/input) fica ao lado da imagem */}
                        <div style={{ marginLeft: "10px", marginTop: "15px" }}>
                          <label
                            htmlFor="cover"
                            className="btn label"
                            style={{
                              backgroundColor: "#3D5EE1",
                              borderRadius: "5px",
                              padding: "5px 10px",
                              color: "white",
                              border: "none",
                              cursor: "pointer",
                              marginBottom: "10px",
                            }}
                          >
                            Mudar foto de capa
                          </label>

                          <input
                            type="file"
                            id="cover"
                            name="cover"
                            accept="image/*"
                            onChange={handleCoverChange}
                            style={{ display: "none" }}
                          />

                          {/* O parágrafo vai abaixo do label/input */}
                          <p style={{ marginTop: "5px", fontSize: "12px" }}>
                            Deve ser JPEG, PNG e não pode exceder 2 MB.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="settings-btns">
                  <button
                    onClick={handleClose}
                    className="btn btn-danger"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto

                      height: "30px", // Ajustar a altura
                    }}
                  >
                    Fechar
                  </button>

                  <button
                    type="submit"
                    onClick={updateProfile}
                    className="btn"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto
                      marginRight: "10px",
                      height: "30px", // Ajustar a altura
                      backgroundColor: "#3D5EE1",
                      color: "white",
                    }}
                  >
                    {loading ? "A salvar..." : "Salvar"}
                  </button>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="Perfil do centro">
            <div className="settings-form">
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Nome do Centro Infantil{" "}
                      <span className="star-red">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Digite Nome do Centro Infantil"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insira o email"
                      name="email"
                      value={formData.email}
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label>Telefone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insira o telefone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>
              <div className="form-group">
                <label>Sobre o centro</label>
                <ReactQuill
                  theme="snow"
                  value={formData.description}
                  onChange={handleDescriptionChange}
                />
                {error.description && (
                  <p style={{ color: "red" }}>{error.description}</p>
                )}
              </div>

              <div className="form-group">
                <label>Políticas de admissão</label>
                <ReactQuill
                  theme="snow"
                  value={formData.policies}
                  onChange={handlePoliciesChange}
                />
                {error.policies && (
                  <p style={{ color: "red" }}>{error.policies}</p>
                )}
              </div>

              <div className="form-group ">
                <div className="settings-btns">
                  <button
                    onClick={handleClose}
                    className="btn btn-danger"
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto

                      height: "30px", // Ajustar a altura
                    }}
                  >
                    Fechar
                  </button>

                  <button
                    type="submit"
                    onClick={updateProfile}
                    className="btn"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto
                      marginRight: "10px",
                      height: "30px", // Ajustar a altura
                      backgroundColor: "#3D5EE1",
                      color: "white",
                      
                    }}
                  >
                    {loading ? "A salvar..." : "Salvar"}
                  </button>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="social" title="Endereço">
            <div className="settings-form">
              <div className="form-group">
                <label>Endereço Completo</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Insira o endereço completo"
                  name="address"
                  value={formData.address}
                  onChange={handleAddressChange}
                />
                {suggestions.length > 0 && (
                  <ul className="suggestions-list">
                    {suggestions.map((suggestion) => (
                      <li
                        key={suggestion.place_id}
                        onClick={() => handleSelectSuggestion(suggestion)}
                      >
                        {suggestion.display_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-6">
                    <label>Província</label>
                    <input
                      type="text"
                      className="form-control"
                      name="province"
                      value={formData.province}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label>Distrito</label>
                    <input
                      type="text"
                      className="form-control"
                      name="district"
                      value={formData.district}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="settings-btns">
                  <button
                    onClick={handleClose}
                    className="btn btn-danger"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto

                      height: "30px", // Ajustar a altura
                    }}
                  >
                    Fechar
                  </button>

                  <button
                    type="submit"
                    onClick={updateAddress}
                    className="btn"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto
                      marginRight: "10px",
                      height: "30px", // Ajustar a altura
                      backgroundColor: "#3D5EE1",
                      color: "white",
                    }}
                  >
                    {loading ? "A salvar..." : "Salvar"}
                  </button>
                </div>
              </div>
            </div>
          </Tab>
          <Tab eventKey="settings" title="Outros detalhes">
            <div className="settings-form">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6 form-group">
                    <label>Inscrição</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Inscrição"
                      name="enrollment_fee"
                      value={formData.enrollment_fee}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6 form-group">
                    <label>Capacidade</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Insira a capacidade"
                      name="capacity"
                      value={formData.capacity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label>Abertura</label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Insira o horário de abertura"
                    name="open_time"
                    value={formData.open_time}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6">
                  <label>Fecho</label>
                  <input
                    type="time"
                    className="form-control"
                    placeholder="Insira o horário de fecho"
                    name="close_time"
                    value={formData.close_time}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="form-group mt-3">
                <div className="settings-btns">
                  <button
                    onClick={handleClose}
                    className="btn btn-danger"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto

                      height: "30px", // Ajustar a altura
                    }}
                  >
                    Fechar
                  </button>

                  <button
                    type="submit"
                    onClick={updateDetails}
                    className="btn"
                    disabled={loading}
                    style={{
                      float: "right",
                      marginTop: "10px",
                      padding: "5px 10px", // Diminuir o espaçamento interno
                      fontSize: "10px", // Ajustar o tamanho do texto
                      marginRight: "10px",
                      height: "30px", // Ajustar a altura
                      backgroundColor: "#3D5EE1",
                      color: "white",
                    }}
                  >
                    {loading ? "A salvar..." : "Salvar"}
                  </button>
                </div>
              </div>
            </div>
          </Tab>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default EditModal;
