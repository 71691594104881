import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import kidsZone from "../assets/img/kidsZone.jpg";

const StudentDetails = () => {
  const { studentId } = useParams();
  const { user } = useAuth();
  const [student, setStudent] = useState(null);

  useEffect(() => {
    const getStudentDetails = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/students/${studentId}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        console.log(data);
        setStudent(data);
      } catch (error) {
        console.error(error);
      }
    };

    getStudentDetails();
  }, [studentId, user]);

  if (!student) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Detalhes do Aluno</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="students.html">Aluno</a>
                  </li>
                  <li className="breadcrumb-item active">Detalhes do Aluno</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12">
                <div className="about-info">
                  <h4>
                    Perfil <i className="feather-more-vertical"></i>
                  </h4>
                </div>
                <div className="student-profile-head">
                  <div className="profile-bg-img">
                    <img
                      src={kidsZone}
                      alt="Profile"
                      style={{ height: "318px" }}
                    />
                  </div>
                  <div className="row">
                    <div className="col-lg-4 col-md-4">
                      <div className="profile-user-box">
                        <div className="profile-user-img">
                          <img src={student.full_picture_url} alt="Profile" />
                          <div className="form-group students-up-files profile-edit-icon mb-0">
                            <div className="uplod d-flex">
                              <label className="file-upload profile-upbtn mb-0">
                                <i className="fa fa-camera"></i>
                                <input type="file" />
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="names-profiles">
                          <h4>{student.name}</h4>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-lg-4 col-md-4 d-flex align-items-center">
                      <div className="follow-btn-group">
                        <button type="button" className="btn btn-info follow-btns">Follow</button>
                        <button type="button" className="btn btn-info message-btns">Message</button>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Card for Personal Details */}
          <div className="col-lg-4">
            <div className="student-personals-grp">
              <div className="card">
                <div className="card-body">
                  <div className="heading-detail">
                    <h4>Detalhes Pessoais :</h4>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Nome</h4>
                      <h5>{student.name}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-birthday-cake"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Data de Nascimento</h4>
                      <h5>
                        {new Date(student.birth_date).toLocaleDateString()}
                      </h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-venus-mars"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Gênero</h4>
                      <h5>{student.gender}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-location-dot"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Endereço</h4>
                      <h5>{student.address}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Telefone de Emergência</h4>
                      <h5>{student.emergency_phone || "N/A"}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-user"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Parente/Guardião</h4>
                      <h5>
                        {student.parent
                          ? `${student.parent.first_name} ${student.parent.last_name}`
                          : "N/A"}
                      </h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-envelope"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Email</h4>
                      <h5>{student.parent.email}</h5>
                    </div>
                  </div>
                  <div className="personal-activity mb-0">
                    <div className="personal-icons">
                      <i className="fa-solid fa-phone"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Telefone</h4>
                      <h5>{student.parent.phone}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Card for Other Details */}
          <div className="col-lg-4">
            <div className="student-personals-grp">
              <div className="card">
                <div className="card-body">
                  <div className="heading-detail">
                    <h4>Detalhes Adicionais :</h4>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-stethoscope"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Notas Médicas</h4>
                      <h5>{student.medical_notes || "N/A"}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-syringe"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Alergias</h4>
                      <h5>
                        {Array.isArray(student.allergies) &&
                        student.allergies.length > 0
                          ? student.allergies.map((allergy, index) => (
                              <span key={index}>
                                {allergy}
                                {index < student.allergies.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))
                          : "N/A"}
                      </h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-pills"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Medicações</h4>
                      <h5>{student.medications || "N/A"}</h5>
                    </div>
                  </div>
                  <div className="personal-activity">
                    <div className="personal-icons">
                      <i className="fa-solid fa-people-group"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Necessidades Especiais</h4>
                      <h5>
                        {Array.isArray(student.special_needs) &&
                        student.special_needs.length > 0
                          ? student.special_needs.map((need, index) => (
                              <span key={index}>
                                {need.name}
                                {index < student.special_needs.length - 1
                                  ? ", "
                                  : ""}
                              </span>
                            ))
                          : "N/A"}
                      </h5>
                    </div>
                  </div>
                  <div className="personal-activity mb-0">
                    <div className="personal-icons">
                      <i className="fa-solid fa-ban"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Restrições Alimentares</h4>
                      <h5>{student.dietary_restrictions || "N/A"}</h5>
                    </div>
                  </div>
                  <div className="personal-activity mb-0">
                    <div className="personal-icons">
                      <i className="fa-solid fa-utensils"></i>
                    </div>
                    <div className="views-personal">
                      <h4>Dieta Preferida</h4>
                      <h5>{student.preferred_diet || "N/A"}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudentDetails;
