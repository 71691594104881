import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import "../assets/css/sidebar.css";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";

const Sidebar = () => {
  const { user } = useAuth();
  const [menus, setMenus] = useState([]);
  const [openSubmenus, setOpenSubmenus] = useState({});
  const [settingsOpen, setSettingsOpen] = useState(false);



  // Função para buscar os dados da API
  useEffect(() => {
    let isMounted = true;
    const fetchPlanData = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/subscription-plan`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        if (isMounted) {
          setMenus(data.menus);
        }
      } catch (error) {
        console.error("Erro ao buscar dados da API:", error);
      }
    };
    fetchPlanData();
    return () => {
      isMounted = false;
    };
  }, [user.school.id]);

  // Função para abrir/fechar submenus
  const toggleMenu = (menuId) => {
    setOpenSubmenus((prevOpenSubmenus) => ({
      ...prevOpenSubmenus,
      [menuId]: !prevOpenSubmenus[menuId],
    }));
  };

  const location = useLocation();

  const toggleSettings = () => {
    setSettingsOpen(!settingsOpen);
  };

 

  // Função para renderizar os menus dinamicamente
  const renderMenu = (menu) => {
    const hasSubmenu = menu.children && menu.children.length > 0;

    return (
      <li key={menu.id} className={`submenu ${openSubmenus[menu.id] ? "open" : ""}`}>
        <a href={menu.url || "#"} onClick={() => hasSubmenu && toggleMenu(menu.id)}>
          <i className={menu.icon}></i> <span>{menu.name}</span>
          {hasSubmenu && <span className="menu-arrow"></span>}
        </a>
        {hasSubmenu && (
          <ul className={`settings-submenu ${openSubmenus[menu.id] ? "show" : ""}`}>
            {menu && menu.children.map((submenu) => (
              <li key={submenu.id}>
                <NavLink to={submenu.url} activeClassName="active">
                  <i className={submenu.icon}></i> <span>{submenu.name}</span>
                </NavLink>
              </li>
            ))}
            
          </ul>
        )}
      </li>
    );
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <ul>
            <li className="menu-title">
              <span>Menu Principal</span>
            </li>
            <li className="submenu">
              <NavLink to="/" exact activeClassName="active">
                <i className="fa fa-columns"></i> <span>Página Inicial</span>
                <span className="menu-arrow"></span>
              </NavLink>
            </li>
            {menus && menus.map((menu) => renderMenu(menu))}
            
            <li
              className={`submenu ${
                settingsOpen || location.pathname.includes("/settings")
                  ? "open"
                  : ""
              }`}
            >
              <a href="#settings" onClick={toggleSettings}>
                <i className="fas fa-cog"></i> <span>Configurações</span>
                <span className="menu-arrow"></span>
              </a>
              <ul
                className={`settings-submenu ${
                  settingsOpen || location.pathname.includes("/settings")
                    ? "show"
                    : ""
                }`}
              >
                <li>
                  <NavLink
                    to="/settings/centro-infantil"
                    activeClassName="active"
                  >
                    <i className="fas fa-school me-2"></i>{" "}
                    <span>Centro Infantil</span>
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    to="/settings/extra-lessons"
                    activeClassName="active"
                  >
                    <i className="fa-solid fa-chess me-2"></i>
                    <span>Extracurriculares</span>
                  </NavLink>
                </li> */}

                {/* <li>
                  <NavLink to="/class" activeClassName="active">
                    <i className="fa-solid fa-sitemap me-2"></i>
                    <span>Classes</span>
                  </NavLink>
                </li> */}
                {/* <li>
                  <NavLink to="/special-treatments" activeClassName="active">
                    <i className="fa-solid fa-user-nurse me-2"></i>
                    <span>Tratamentos Especiais</span>
                  </NavLink>
                </li>
                
                  <li>
                    <NavLink to="/settings/employees" activeClassName="active">
                      <i className="fa-solid fa-users me-2"></i>
                      <span>Colaboradores</span>
                    </NavLink>
                  </li> */}
              
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
