import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { Modal, Button, Form } from "react-bootstrap";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import ToastAlert from "../utils/ToastAlert";

const Registration = () => {
  const [searchName, setSearchName] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchDate, setSearchDate] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const { user } = useAuth();
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedEnrolment, setSelectedEnrolment] = useState(null);
  const [body, setbody] = useState("");
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [enrolmentData, setEnrolmentData] = useState([]);

  useEffect(() => {
    const fetchEnrolments = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/registrations`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Erro ao buscar inscrições");
        }

        const data = await response.json();
        setEnrolmentData(data.data);
        setUpdatedStatus();
      } catch (error) {
        console.error("Erro ao buscar inscrições:", error);
      }
    };

    fetchEnrolments();
  }, [user.school.id]);

  

  // API de atualizar o status do aluno
  const updateStudentStatus = async () => {
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/registrations/${selectedEnrolment}?status=${updatedStatus}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ body }), // Incluindo comentários se necessário
        }
      );
      

      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "Success", text: message });

        setEnrolmentData((prevData) =>
          prevData.map((student) =>
            student.id === selectedEnrolment
              ? { ...student, status: updatedStatus }
              : student
          )
        );
        setUpdatedStatus("");
        setLoading(false);
        closeModal(); // Fechar o modal após atualização
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao enviar os dados:", error);
      setLoading(false);
    }
  };

  const filteredStudents = enrolmentData.filter((student) => {
    // Verifica se os campos necessários estão definidos
    const studentName = student.student?.name?.toLowerCase() || "";
    const studentDate = student.created_at || "";
    const studentStatus = student.status || "";

    return (
      studentName.includes(searchName.toLowerCase()) &&
      studentDate.includes(searchDate) &&
      (statusFilter === "" || studentStatus === statusFilter)
    );
  });

  // Função para converter dados para CSV e iniciar o download
  const downloadCSV = () => {
    const csvRows = [];
    const headers = [
      "#",
      "Nome do Educando",
      "Data de Inscrição",
      "Status",
      "Nome do Encarregado",
    ];
    csvRows.push(headers.join(","));

    filteredStudents.forEach((student, index) => {
      const row = [
        index + 1,
        student.student.name,
        formatDate(student.registration_date),
        student.status,
        `${student.student.parent.first_name} ${student.student.parent.last_name}`,
      ];
      csvRows.push(row.join(","));
    });

    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "inscricoes.csv";
    link.click();
  };

  const openModal = (student) => {
    setSelectedStudent(student);
    setSelectedEnrolment(student.id); // Armazena o id do enrolment
    setbody(""); 
    setUpdatedStatus(student.status); 
  };

  const closeModal = () => {
    setSelectedStudent(null);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Aprovada":
        return "btn-success"; // Verde
      case "Rejeitada":
        return "btn-danger"; // Vermelho
      case "Cancelada":
        return "btn-secondary"; // Cinza
      case "Em Revisão":
        return "btn-info"; // Azul
      case "Completa":
        return "btn-primary"; // Azul Escuro
      case "Aguardando Documentos":
        return "btn-warning"; // Amarelo
      default:
        return "btn-warning"; // Pendente ou qualquer outro status desconhecido
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Pre-Inscrições Recebidas</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/enrolment">Pre-Inscrições</a>
                  </li>
                  <li className="breadcrumb-item active">
                    Visualizar Pre-Inscrições
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="student-group-form">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar por Nome do Educando..."
                  value={searchName}
                  onChange={(e) => setSearchName(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Buscar por Data de Inscrição..."
                  value={searchDate}
                  onChange={(e) => setSearchDate(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <select
                  className="form-control"
                  value={statusFilter}
                  onChange={(e) => setStatusFilter(e.target.value)}
                >
                  <option value="">Filtrar por Status</option>
                  <option value="Pendente">Pendente</option>
                  <option value="Aprovadao">Aprovado</option>
                  <option value="Rejeitado">Rejeitado</option>
                  <option value="Cancelado">Cancelado</option>
                  <option value="Em Revisão">Em Revisão</option>
                  <option value="Completa">Completa</option>
                  <option value="Aguardando Documentos">
                    Aguardando Documentos
                  </option>
                </select>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="search-student-btn">
                <button type="button" className="btn btn-primary">
                  Buscar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table comman-shadow">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Lista de Pre-Inscrições</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <button
                        className="btn btn-outline-primary me-2"
                        onClick={downloadCSV}
                      >
                        <i className="fas fa-download"></i> Download
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Nome do Educando</th>
                        <th>Data da Inscrição</th>
                        <th>Ano</th>
                        <th>Tipo de relacao</th>
                        <th>Observacão</th>
                        <th>Turma</th>
                        <th>Status</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {enrolmentData.map((student, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{student.student.name}</td>
                          <td>{student.registration_date}</td>
                          <td>{student.year}</td>
                          <td>{student.student.relationship}</td>
                          <td>{student.observation || "Sem observacão"}</td>
                          <td>{student.class.name}</td>

                          <td>
                          <Button
                              className={`text-white ${getStatusClass(student.status)}`}
                            >
                              {student.status}
                            </Button>
                          </td>

                          <td className="text-end">
                            <button
                              className="btn btn-sm btn-outline-primary"
                              title="ver todos detalhes"
                              onClick={() => openModal(student)}
                            >
                              <i className="fas fa-eye"></i>
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal size="lg" centered show={selectedStudent !== null} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>Detalhes da Inscrição</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedStudent && (
              <>
                <h5>{selectedStudent.student.name}</h5>
                <p>
                  <b>Turma:</b> {selectedStudent.class.name}
                </p>
                <p>
                  <b>Data de Inscrição:</b>{" "}
                  {selectedStudent.registration_date}
                </p>
                <p>
                  <b>Endereco:</b> {selectedStudent.address}
                </p>
                <p>
                  <b>Encarregado:</b>{" "}
                  {selectedStudent.parent.full_name}
                </p>
                <p>
                  <b>Telefone do Encarregado:</b>{" "}
                  {selectedStudent.parent.phone}
                </p>
                <p>
                  <b>Origem:</b> {selectedStudent.origin}
                </p>
                <p>
                  <b>Alergias:</b> {selectedStudent.student.allergies}
                </p>
                <p>
                  <b>Gênero:</b> {selectedStudent.student.gender}
                </p>
                <Form.Group>
                  <Form.Label>Comentários</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={body}
                    onChange={(e) => setbody(e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    value={updatedStatus}
                    onChange={(e) => setUpdatedStatus(e.target.value)}
                  >
                    <option value="Pendente">Pendente</option>
                    <option value="Aprovada">Aprovada</option>
                    <option value="Rejeitada">Rejeitada</option>
                    <option value="Cancelada">Cancelada</option>
                    <option value="Em Revisão">Em Revisão</option>
                    <option value="Completa">Completa</option>
                    <option value="Aguardando Documentos">
                      Aguardando Documentos
                    </option>
                  </Form.Control>
                </Form.Group>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-danger"
              disabled={loading}
              style={{ marginRight: "10px" }}
              onClick={updateStudentStatus}
            >
              Salvar
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Registration;
