import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import { Button, Modal, Form } from "react-bootstrap";
import ToastAlert from "../utils/ToastAlert";
import env from "../utils/env";
import { useAuth } from "../Context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const SpecialTreatment = () => {
  const [treatments, setTreaments] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [newTreatmentName, setNewTreatmentName] = useState("");
  const [newTreatmentPrice, setNewTreatmentPrice] = useState("");
  const [selectTreatment, setSelectTreatment] = useState(null);
  const [editTreatmentName, setEditTreatmentName] = useState("");
  const [editTreatmentPreco, setEditTreatmentPreco] = useState("");
  const { user } = useAuth();
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/special-needs`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setTreaments(data);
      } catch (error) {
        console.error("Erro ao buscar os dados das turmas:", error);
      }
    };

    fetchTreatments();
  }, [user]);

  const handleCloseModal = () => {
    setShowAddModal(false);
  };

  // Abrir modal de edição
  const handleShowEditModal = (treatment) => {
    setSelectTreatment(treatment);
    setEditTreatmentName(treatment.name);
    setEditTreatmentPreco(treatment.price);
    setShowEditModal(true);
  };

  const handleNewTreatment = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/special-needs`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name: newTreatmentName,
            price: newTreatmentPrice,
          }),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({
          icon: "success",
          text: message,
        });

        setTreaments((prevTreatments) => [
          ...prevTreatments,
          {
            name: newTreatmentName,
            price: newTreatmentPrice,
          },
        ]);

        setLoading(false);
        handleCloseModal(); // Fechar o modal após adicionar a atividade
      } else {
        ToastAlert({ icon: "error", title: "Erro", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao adicionar:", error);
    }
  };

  const handleEdit = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/special-needs/${selectTreatment.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name: editTreatmentName,
            price: editTreatmentPreco,
          }),
        }
      );
      const { message, errors } = await response.json();

      if (response.ok) {
        ToastAlert({ icon: "success", title: "success", text: message });
        setTreaments((prevTreatment) =>
          prevTreatment.map((treatment) =>
            treatment.id === selectTreatment.id
              ? {
                  ...treatment,
                  name: editTreatmentName,
                  price: editTreatmentPreco,
                }
              : treatment
          )
        );
        setLoading(false);
        setShowEditModal(false);
      } else {
        ToastAlert({ icon: "error", title: "error", text: errors });
        setLoading(false);
      }
    } catch (error) {
      console.error("Erro ao editar:", error);
    }
  };

  const handleDeleteSubmit = async (treatment) => {
    if (!treatment || !treatment.id) {
      console.error("O ID do tratamento é nulo ou indefinido");
      return;
    }

    MySwal.fire({
      title: "Aviso",
      text: "Você não poderá reverter isso!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Apagar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await fetch(
            `${env.base_url}schools/${user.school.id}/special-needs/${treatment.id}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json",
              },
            }
          );
          const { message, errors } = await response.json();

          if (response.ok) {
            ToastAlert({ icon: "success", title: "Sucesso", text: message });
            // Filter out the deleted treatment from the state
            setTreaments((prevTreatments) =>
              prevTreatments.filter((treat) => treat.id !== treatment.id)
            );
          } else {
            ToastAlert({ icon: "error", title: "Erro", text: errors });
          }
        } catch (error) {
          console.error("Erro durante a exclusão:", error);
        }
      }
    });
  };


  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Tratamento especial</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="index.html">Dashboard</a>
                </li>
                <li className="breadcrumb-item active">Tratamento especial</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Tratamento especial</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <button
                        className="btn btn-primary"
                        onClick={() => setShowAddModal(true)}
                      >
                        <i className="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>#</th>
                        <th>Nome</th>
                        {/* <th>Descricao</th> */}
                        <th>Preco</th>
                        <th className="text-end">Ação</th>
                      </tr>
                    </thead>
                    <tbody>
                      {treatments.map((treat, index) => (
                        <tr key={treat.id}>
                          <td>{index + 1}</td>
                          <td>
                            <h2>{treat.name}</h2>
                          </td>
                          {/* <td>{treat.description}</td> */}
                          <td>{treat.price}</td>
                          <td className="text-end">
                            <div className="actions">
                              <Button
                                title="Editar"
                                className="btn btn-sm btn-success me-1"
                                onClick={() => handleShowEditModal(treat)}
                              >
                                <i
                                  className="fas fa-edit"
                                  style={{ color: "white" }}
                                ></i>
                              </Button>
                              <Button
                                className="btn btn-sm btn-danger"
                                title="Deletar"
                                onClick={() => handleDeleteSubmit(treat)}
                              >
                                <i className="fas fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal centered size="lg" show={showAddModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="modal-title">Adicionar tratamento especial</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newTreamentName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                className="mb-3"
                value={newTreatmentName}
                onChange={(e) => setNewTreatmentName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="newTreamentPrice">
              <Form.Label>Preco</Form.Label>
              <Form.Control
                type="number"
                value={newTreatmentPrice}
                onChange={(e) => setNewTreatmentPrice(e.target.value)}
              />
            </Form.Group>
            <Button className="mt-3" onClick={handleNewTreatment}>
              {loading ? "A carregar..." : "Adicionar"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal
        centered
        size="lg"
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3 className="modal-title">Editar tratamento especial</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="newTreamentName">
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                className="mb-3"
                value={editTreatmentName}
                onChange={(e) => setEditTreatmentName(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="newTreamentPrice">
              <Form.Label>Preco</Form.Label>
              <Form.Control
                type="number"
                value={editTreatmentPreco}
                onChange={(e) => setEditTreatmentPreco(e.target.value)}
              />
            </Form.Group>
            <Button className="mt-3" onClick={handleEdit}>
              {loading ? "A carregar..." : "Atualizar"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SpecialTreatment;
