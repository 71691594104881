import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import env from "../utils/env";
import { format } from "date-fns";
import { useAuth } from "../Context/AuthContext";
import { Button, Modal, Form } from "react-bootstrap";
import ToastAlert from "../utils/ToastAlert";

const Student = () => {
  const [classes, setClasses] = useState([]);
  const [levels, setLevels] = useState([]);
  const [students, setStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [status, setStatus] = useState("");
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [name, setName] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("");
  const [relationship, setRelationship] = useState("");
  const [address, setAddress] = useState("");
  const [errors, setErrors] = useState({});
  const [amount, setAmount] = useState("");
  const { user } = useAuth();
  const [year, setYear] = useState("");

  const formattedDate = birthDate
    ? format(new Date(birthDate), "yyyy-MM-dd")
    : "";

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/students`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setStudents(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchClasses = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/classes`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setClasses(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Erro ao buscar os dados das turmas:", error);
      }
    };

    const fetchLevels = async () => {
      try {
        const response = await fetch(
          `${env.base_url}schools/${user.school.id}/levels`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          }
        );
        const { data } = await response.json();
        setLevels(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Erro ao buscar os dados dos níveis:", error);
      }
    };

    fetchClasses();
    fetchLevels();
    fetchStudents();
  }, [user]);

  const getStatusClass = (status) => {
    switch (status) {
      case "ativa":
        return "btn-success";
      case "desistiu":
        return "btn-danger";
      case "concluída":
        return "btn-primary";
      case "pendente":
        return "btn-warning";
      case "cancelada":
        return "btn-secondary";
      case "suspensa":
        return "btn-dark";
      default:
        return "btn-light";
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleShow = (student) => {
    setSelectedStudent(student);
    setStatus(student.enrolment.status);
    setSelectedClass(student.enrolment.class?.id || "");
    setSelectedLevel(student.enrolment.level?.id || "");
    setName(student.name || "");
    setBirthDate(student.birth_date || "");
    setGender(student.gender || "");
    setRelationship(student.relationship || "");
    setAddress(student.address || "");
    setAmount(student.enrolment.level?.amount || "");
    setYear(student.enrolment.year || "");
    setShowModal(true);
  };

  const validateFields = () => {
    const errors = {};

    if (!name)
      errors.name =
        "É obrigatória a indicação de um valor para o campo nome do aluno.";
    if (!birthDate)
      errors.birthDate =
        "É obrigatória a indicação de um valor para o campo data de nascimento do aluno.";
    if (!gender)
      errors.gender =
        "É obrigatória a indicação de um valor para o campo gênero do aluno.";
    if (!relationship)
      errors.relationship =
        "É obrigatória a indicação de um valor para o campo relacionamento com o aluno.";
    if (!address)
      errors.address =
        "É obrigatória a indicação de um valor para o campo endereço do aluno.";
    if (!selectedClass)
      errors.class =
        "É obrigatória a indicação de um valor para o campo ID da turma.";
    if (!status)
      errors.status =
        "É obrigatória a indicação de um valor para o campo estado do aluno.";

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateFields()) return;

    try {
      const response = await fetch(
        `${env.base_url}schools/${user.school.id}/enrolments/${selectedStudent.id}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            name,
            birth_date: birthDate,
            gender,
            relationship,
            address,
            status,
            class: selectedClass,
            level: selectedLevel,
            year,
            amount: levels.find((level) => level.id === selectedLevel)?.amount,
          }),
        }
      );

      const { message, errors } = await response.json();

      if (response.ok) {
        const updatedStudents = students.map((student) =>
          student.id === selectedStudent.id
            ? {
                ...student,
                enrolment: {
                  ...student.enrolment,
                  status,
                  class: selectedClass,
                  level: selectedLevel,
                },
                name,
                birth_date: birthDate,
                gender,
                relationship,
                address,
              }
            : student
        );
        ToastAlert({ icon: "success", title: "Success", text: message });
        setStudents(updatedStudents);
        handleClose();
      } else {
        ToastAlert({ icon: "error", title: "Error", text: errors });
        console.log(errors);
      }
    } catch (error) {
      console.error(error);
      handleClose();
    }
  };

  return (
    <div className="page-wrapper">
      <Header />
      <Sidebar />
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-sub-header">
                <h3 className="page-title">Educando</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="teachers.html">Educando</a>
                  </li>
                  <li className="breadcrumb-item active">Educandos</li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="student-group-form">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by ID ..."
                />
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Name ..."
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by Phone ..."
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="search-student-btn">
                <button type="btn" className="btn btn-primary">
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table comman-shadow">
              <div className="card-body">
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col">
                      <h3 className="page-title">Educandos</h3>
                    </div>
                    <div className="col-auto text-end float-end ms-auto download-grp">
                      <a
                        href="teachers.html#"
                        className="btn btn-outline-primary me-2"
                      >
                        <i className="fas fa-download"></i> Download
                      </a>
                      <a href="/add-student" className="btn btn-primary">
                        <i className="fas fa-plus"></i>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="table-responsive">
                  <table className="table border-0 star-student table-hover table-center mb-0 datatable table-striped">
                    <thead className="student-thread">
                      <tr>
                        <th>
                          <div className="form-check check-tables">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value="something"
                            />
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Nome</th>
                        <th>Data de Nascimento</th>
                        <th>Genero</th>
                        <th>Nome do Responsável</th>
                        <th>Telefone Responsável</th>
                        <th>Endereço</th>
                        <th>Turma</th>
                        <th>Mensalidade</th>
                        <th>Estado do Educando</th>
                        <th className="text-end">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((student, index) => (
                        <tr key={index}>
                          <td>
                            <div className="form-check check-tables">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value="something"
                              />
                            </div>
                          </td>
                          <td>{index + 1}</td>
                          <td>
                            <h2 className="table-avatar">
                              <a
                                href="/student"
                                className="avatar avatar-sm me-2"
                              >
                                <img
                                  className="avatar-img rounded-circle"
                                  src={student.full_picture_url}
                                  alt="UserImage"
                                />
                              </a>
                              <a href="/student">{student.name}</a>
                            </h2>
                          </td>
                          
                          <td>{student.birth_date}</td>
                          <td>{student.gender}</td>
                          <td>{student.parent.first_name}</td>
                          <td>{student.parent.phone}</td>
                          <td>{student.address}</td>
                          <td>{student.enrolment.class?.name || "-"}</td>
                          <td>{student.enrolment.level.amount}</td>
                          <td>
                            <Button
                              className={`text-white ${getStatusClass(
                                student.enrolment.status
                              )}`}
                            >
                              {student.enrolment.status}
                            </Button>
                          </td>
                          <td className="text-end">
                            <div className="actions">
                              <a
                                href={`/student/${student.id}`}
                                className="btn btn-sm bg-success-light me-2"
                              >
                                <i className="fa-regular fa-eye"></i>
                              </a>
                              
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Editing Student Enrolment */}
        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Aluno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do aluno"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formBirthDate">
                <Form.Label>Data de Nascimento</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Data de nascimento do aluno"
                  value={formattedDate}
                  onChange={(e) => setBirthDate(e.target.value)}
                  isInvalid={!!errors.birthDate}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.birthDate}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formGender">
                <Form.Label>Gênero</Form.Label>
                <Form.Control
                  as="select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  isInvalid={!!errors.gender}
                >
                  <option value="">Selecione...</option>
                  <option value="M">Masculino</option>
                  <option value="F">Feminino</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formRelationship">
                <Form.Label>Relacionamento</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Relacionamento com o aluno"
                  value={relationship}
                  onChange={(e) => setRelationship(e.target.value)}
                  isInvalid={!!errors.relationship}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.relationship}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formAddress">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Endereço do aluno"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  isInvalid={!!errors.address}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.address}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formAmount">
                <Form.Label>Valor da Mensalidade</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Valor da mensalidade"
                  value={amount} // Exibe o valor de amount
                  onChange={(e) => setAmount(e.target.value)}
                  isInvalid={!!errors.amount}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.amount}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formClass">
                <Form.Label>Turma</Form.Label>
                <Form.Control
                  as="select"
                  value={selectedClass}
                  onChange={(e) => setSelectedClass(e.target.value)}
                  isInvalid={!!errors.class}
                >
                  <option value="">Selecione...</option>
                  {classes.map((classItem) => (
                    <option key={classItem.id} value={classItem.id}>
                      {classItem.name}
                    </option>
                  ))}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.class}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formStatus">
                <Form.Label>Status</Form.Label>
                <Form.Control
                  as="select"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  isInvalid={!!errors.status}
                >
                  <option value="">Selecione...</option>
                  <option value="ativa">Ativa</option>
                  <option value="desistiu">Desistiu</option>
                  <option value="concluída">Concluída</option>
                  <option value="pendente">Pendente</option>
                  <option value="cancelada">Cancelada</option>
                  <option value="suspensa">Suspensa</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.status}
                </Form.Control.Feedback>
              </Form.Group>

              <Button variant="primary" onClick={handleSubmit}>
                Salvar
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <Footer />
    </div>
  );
};

export default Student;